import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Contact extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact" />
        <h1 className="mt-32 text-4xl font-bold">Contact</h1>
        <p className="max-w-lg">
          Feel free to reach out to me by my email at{" "}
          <span className="text-blue-400 font-bold">
            justinzliwag@gmail.com
          </span>{" "}
          <br />
          <br />I respond to emails from{" "}
          <span className="text-blue-400 font-bold">5:00pm - 5:30pm</span>. I
          apologize in advance If I don’t respond to your email on time or at
          all. I am usually working on a project, writing, or shuffling cards.
        </p>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
